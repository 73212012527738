@import './alerts.scss';
@import './auth.scss';
.rbc-addons-dnd-resize-ew-anchor {
	color: aquamarine !important;
}

.rbc-addons-dnd-resize-ns-icon {
	color: aquamarine !important;
	width: 30% !important;
	height: 0.25rem !important;
}

.rbc-addons-dnd .rbc-addons-dnd-resize-ns-anchor:first-child {
	display: none !important;
}

.rbc-day-slot .rbc-event-label {
	display: none;
}

.rbc-day-slot .rbc-event-content {
	margin-top: 0.1rem;
	margin-left: 0.5rem;
}
.rbc-day-slot {
	.rbc-events-container {
		.rbc-event {
			margin-top: 0 !important;
			border-radius: 1.8rem !important;
		}
	}
}

#colorIndicator {
	width: 0.5rem;
	height: 0.5rem;
	-webkit-border-radius: 0.25rem;
	-moz-border-radius: 0.25rem;
	border-radius: 0.25rem;
}

.rbc-button-link {
	background: none;
	border: none;
	cursor: pointer;
}

.rbc-selected {
	.rbc-event-content {
		overflow-y: scroll !important;
		scrollbar-width: none;
		-ms-overflow-style: none;
	}
}

.rbc-event-content::-webkit-scrollbar {
	width: 0;
	height: 0;
}
